import { Trans } from "@lingui/macro";
import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import React, { useState } from "react";
import { motion } from "framer-motion";
import CardColor from "../../components/CardColor";
import Container from "../../components/Container";
import Header from "../../components/Header";
import Seo from "../../components/seo";
import SecondaryButton from "../../components/SecondaryButton";
import HeroLandingV2 from "../../components/HeroLandingV2";
import ProductModal from "../../components/ProductModal";
import pinkIcon from "../../images/icons/pink.png";
import greenIcon from "../../images/icons/green.png";
import yellowIcon from "../../images/icons/yellow.png";
import logo from "../../images/success-case/generali/logo-generali.png";
import heroImg from "../../images/success-case/generali/hero.png";
import introImg from "../../images/success-case/generali/intro.png";
import projectOutcomeImg from "../../images/success-case/generali/project-outcome.png";
import { theme } from "../../themeDark";

const metadata = {
  title: "Success Case - Generali",
  description: "Allfunds Digital has created and successfully deployed a global online sales application for Generali, incorporating subscription and management of all savings solutions online.",
};

const ITEMS = [
  {
    icon: yellowIcon,
    color: theme.palette.colors.yellow.main,
    title: (
      <Trans>
        Management
      </Trans>
    ),
    description: (
      <Trans>
        Streamline the management of Unit-Linked products as Investment Funds portfolios, including portfolio rebalancing. This comprehensive online solution empowers Generali to efficiently oversee and optimize their unit-linked offerings.
      </Trans>
    ),
  },
  {
    icon: greenIcon,
    color: theme.palette.colors.green.main,
    title: <Trans>Reporting</Trans>,
    description: (
      <Trans>
        Provide in-depth reporting on unit-linked funds portfolios.  including holdings, performance analysis, risk-return, asset allocation, transactions, and more. Generali can make informed decisions, optimize investment strategies, and proactively manage risk.
      </Trans>
    ),
  },
  {
    icon: pinkIcon,
    color: theme.palette.colors.purple.main,
    title: <Trans>Client Portal</Trans>,
    description: (
      <Trans>
        Empower end clients to track their investments and make informed decisions with access to their Unit-link Funds portfolio reporting and the ability to self-manage the portfolio of funds behind their Unit-linked policy.
      </Trans>
    ),
  },
];

const getAnimationProps = (direction) => ({
  initial: "hidden",
  whileInView: "visible",
  viewport: { once: true, amount: 0.8 },
  transition: { duration: 0.8 },
  variants: {
    hidden: { opacity: 0, y: direction === "down" ? 70 : -70 },
    visible: { opacity: 1, y: 0 },
  },
});

function AnimatedDiv({ isMobile, direction, children }) {
  if (isMobile) {
    return <div>{children}</div>;
  }
  return <motion.div {...getAnimationProps(direction)}>{children}</motion.div>;
}

function Generali() {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showModal, setShowModal] = useState(false);

  const handleOnclickInfo = () => {
    setShowModal(true);
  };

  const renderRequestButton = () => {
    return (
      <SecondaryButton
        handleOnclickInfo={() => setShowModal(true)}
        text={<Trans>Request info</Trans>}
      />
    );
  };

  const renderCard = ({ color, icon, title, description, key }) => {
    return (
      <Grid item xs={12} sm={6} lg={4} sx={{ transform: { sm: key % 2 === 0 ? "translateY(0)" : "translateY(50%)", lg: "translateY(0)"} }}>
        <CardColor mt={0} color={color} icon={icon} title={title} description={description} />
      </Grid>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Seo title={metadata.title} description={metadata.description} />
      <Header darkMode={true} />
      <HeroLandingV2
        title={
          <Trans>Streamlining Unit-Linked Management</Trans>
        }
        description={
          <Trans>
            Generali is one of the largest global insurance and asset management providers. Established in 1831, it is
            present in <strong>over 50 countries</strong> in the world, with almost 82 thousand employees and <strong>164 thousand agents</strong> serving <strong>70 million customers</strong>.
          </Trans>
        }
        handleOnclickInfo={() => handleOnclickInfo()}
        backgroundImage={heroImg}
        kindImage={logo}
      />
      <Container pt={{ xs: 8, md: 14.5, lg: 8 }}>
        <Grid container spacing={{ xs: 3, lg: 6 }} alignItems="center">
          <Grid item xs={12} sm={6} md={7}>
            <Stack
              spacing={3}
            >
              <Typography variant="h2">
                Client Needs
              </Typography>
              <Typography>
                Generali in Spain sought to enhance its offering of Unit-Linked products as Investment Funds portfolios. They were facing challenges with their existing platform's limitations in efficiently creating, offering, and managing these portfolios. They needed a platform to efficiently create, offer, and manage these Unit-Linked Investment Funds portfolios, empowering both their customers and internal users to take control of their Unit-Linked Policies.
              </Typography>
              <Box display="flex" justifyContent={{ xs: "center", sm: "flex-start" }}>
                {renderRequestButton()}
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={5} pt={2} justifyContent={{ xs: "center", md: "flex-start" }}>
            <img width="100%" alt="third" src={introImg} />
          </Grid>
        </Grid>
      </Container>
      <Container pt={{ xs: 8, md: 14.5, lg: 8 }}>
        <Stack flexDirection="column" alignItems="center" spacing={2}>
          <Typography variant="h3">
            Allfunds Solution
          </Typography>
          <Box pt={1}>
            <AnimatedDiv isMobile={isMobile} direction="down">
              <Grid container spacing={2}>
                {ITEMS.map((item, key) => (
                  renderCard({ ...item, key })
                ))}
              </Grid>
            </AnimatedDiv>
          </Box>
          <Box sx={{ display: { xs: "none", lg: "block" }, pt: 1, pb: 1 }}>
            {renderRequestButton()}
          </Box>
        </Stack>
      </Container>
      <Container pt={{ xs: 8, md: 14.5, lg: 8 }} pb={{ xs: 8, md: 14.5, lg: 8 }}>
        <Grid container spacing={2} alignItems="center" flexDirection={{ xs: "column-reverse", lg: "row" }}>
          <Grid item xs={12} lg={7} justifyContent={{ xs: "center", md: "flex-start" }}>
            <img width="100%" alt="third" src={projectOutcomeImg} />
          </Grid>
          <Grid item xs={12} lg={5}>
            <Stack
              spacing={3}
            >
              <Typography variant="h2">
                Project Outcome
              </Typography>
              <Stack>
                <Typography fontWeight={600}>Scalability</Typography>
                <Typography>
                  The solution is designed to grow with Generali's business, accommodating increased demand and expanding product offerings.
                </Typography>
              </Stack>
              <Stack>
                <Typography fontWeight={600}>Time Saving</Typography>
                <Typography>
                  Automated processes and streamlined workflows free up valuable time for both Generali's staff and their customers.
                </Typography>
              </Stack>
              <Stack>
                <Typography fontWeight={600}>User-Friendly Ecosystem</Typography>
                An intuitive interface and accessible tools enhance the user experience for all stakeholders.
                <Typography>
                </Typography>
              </Stack>
              <Box display="flex" justifyContent={{ xs: "center", md: "flex-start" }}>
                {renderRequestButton()}
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      {showModal && (
        <ProductModal
          onClose={() => setShowModal(false)}
          subject="Success Case - Generali"
          product="success-case-generali"
          hasTemplate={false}
        />
      )}
    </ThemeProvider>
  );
}

export default Generali;
